import { Carousel } from "primereact/carousel";
import "./style.css";
// import {PartnerCard} from "../Cards/PartnerCard";
import PartnerCard from "../Cards/partnerCard.js"

export default function ResponsiveCarousel({ items = [] }) {
  const responsiveOptions = [
    {
      breakpoint: "1400px",
      numVisible: 5,
      numScroll: 1,
    },
    {
      breakpoint: "1199px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 2,
      numScroll: 1,
    },
  ];
  const partnerCard = (item) => {
    return <PartnerCard item={item} />;
  };
  return (
    <div className="w-full">
      <Carousel
        value={items}
        numScroll={1}
        numVisible={5}
        responsiveOptions={responsiveOptions}
        itemTemplate={partnerCard}
        showIndicators={false}
        autoplayInterval={2000}
        circular
      />
    </div>
  );
}
