import React from "react";
import Header from "../../views/Header";
import Footer from "../../views/Footer";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import HomePage from "./HomePage";

const Home = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  return (
    <div>
      <Header />
      {currentPath === "/" ? <HomePage /> : <Outlet />}
      <Footer />
    </div>
  );
};

export default Home;
