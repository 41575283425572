
export const TopupInsuranceData = [
  {
    componentType: "title",
    content: " Top-up ",
    spantext: " health insurance"
  },
  {
    componentType: "paragraph",
    content: `A top-up health insurance is a supplementary health insurance, providing additional amount of
    insurance, over and above the base insurance policy. Contractually
     a top-up insurance health
    insurance policy is not connected to base insurance policy, but sum insured under the base health
    insurance policy can be treated as a
     deductible for top up health insurance policy. By this
    arrangement insured's overall sum insured limit under health insurance is enhanced but at a
    reduced premium,
     because the deductibles in top up health insurance plan reduce the premium.
    This has become a very attractive strategy for health insurance customers.`
  },
  {
    componentType: "paragraph",
    content: `Since top up health insurance policy is also a health insurance policy, its structure, terms and
    conditions are similar to any standard health insurance plan.
    Any person familiar with the working of
    any of the standard health insurance plan would have no difficulty in understanding the terms and
    conditions of top-up
    health insurance plans.`
  },
  {
    componentType: "paragraph",
    content: `When exploring top-up health insurance, we come across a term “super top-up” health insurance.
    The difference between top-up health insurance and super
    top-up is given below:.`
  },
 
];


export const TopupInsuranceCardData =[]
 