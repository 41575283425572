import React from 'react'
import styles from "./style.module.css"

const List = ({items}) => {
  return (
    <div className={styles.listContainer}>
      {items.map((item, index) => (
        <div key={index} className={styles.listItem}>
          <span className={styles.bullet}>•</span>
          <p className={styles.itemText}>{item}</p>
        </div>
      ))}
    </div>
  )
}

export default List