import React from "react";

export const BikeInsuranceData = [
  {
    componentType: "title",
    content: " Motor",
    spantext: " vehicle insurance"
  },
  {
    componentType: "paragraph",
    content: `In spite of you adopting best of the methods to protect your vehicle, accidents and mishaps happen, leading to damage to vehicle and injuries/death to persons.Proper insurance cover can go a long way in dealing with the financial consequences of such accidents .Basically, a motor vehicle insurance deals with damages to car due to various perils and the legal compensation to victims of accidents caused by your vehicle. However, depending on the type of vehicle &amp; use of vehicle the terms of cover vary. Also, coverages addressing various situations are also provided by insurance companies.`
  },
  {
    componentType: "paragraph",
    content: `Insuright Brokers provides you most appropriate advices through understanding your insurance needs and suggest you the best possible coverages available in the market. As long as you are the owner of the vehicle, we will be there with you, from the proposal stage, negotiation stage, assisting you in finalizing the best of the coverage; terms and conditions, claims support, assist you in disputere solution and during the renewal of the policy.`
  },
  {
    componentType: "title",
    content: "Two-wheeler ",
    spantext: "insurance"
  },
  {
    componentType: "paragraph",
    content: `Though there is no legal definition for two-wheeler, however Motor Vehicles Act, 1988 defines motor cycle as a two-wheeler .Driving license classifies motor cycle as motor cycle (with gear) &amp; motor cycle (without gear). Two-wheeler insurance is applicable for motorcycles, scooters, mopeds. e-bikes, e-scooters.`
  },
  {
    componentType: "paragraph",
    content:
      "As per Motor Vehicles Act, 1988 no vehicle is permitted to ply in a public place unless a valid insurance policy covers the vehicle."
  },
  {
    componentType: "paragraph",
    content: `The coverages for a two-wheeler can be broadly classified into 4 sections.`
  },
  {
    componentType: "list",
    content: [
      `Own-damage section`,
      `Personal Accident cover for the owner cum driver section`,
      `Special covers through Add Ons section`
    ]
  },
  {
    componentType: "paragraph",
    content: (
      <>
        <b>Own damage section</b> provides cover against the financial loss to
        insured in repairing / replacing the parts damaged due to accidents,
        fire, explosion lightning, riots, strikes, malicious acts, natural
        causes like storm, flood etc., earthquake, terrorism, burglary, theft,
        landslide ,rockslides. Total loss of the vehicle due to above causes is
        also covered.
      </>
    )
  },
  {
    componentType: "paragraph",
    content: `However, this section does not cover depreciation, wear &amp; tear, failures, breakages, consequential loss, damages to tyres and tubes(unless vehicle is also damaged at the same time, subject to 50% depreciation), loss when driving under influence of alcohol, loss when driving with invalid license.`
  },
  {
    componentType: "paragraph",
    content: (
      <>
        <b>Third Party Section </b> covers the liability of the owner of the
        vehicle that he/she is legally liable under the Motor Vehicles Act, 1988
        for the death or disability to a third-party person due negligent use of
        the vehicle in a public place. It also the liability of the owner under
        this Act for the damages to the property belonging to the third party
        due to negligent use of the vehicle in public place. It also covers the
        cost of defending the claim for compensation by the third parties.
      </>
    )
  },
  {
    componentType: "paragraph",
    content: (
      <>
        <b> Personal Accident Section </b> for owner cum driver provides
        compensation for death or disability to owner cum driver of the insured
        car due to an accident while driving / mounting into / dismounting from
        the vehicle.
      </>
    )
  },
  {
    componentType: "paragraph",
    content: `Exclusions applicable to all the sections includes`
  },
  {
    componentType: "list",
    content: [
      `Loss or damages to vehicle / liability caused or created outside the geographical limits.`,
      `Any claim arising out of contractual liability`,
      `Loss or damage or liability caused or created whilst using the vehicle arising out of use of the
      vehicle in violation of the “Limitations as to Use” clause of the vehicle.`,
      `Loss or damage or liability caused or created whilst driving vehicle in violation of “Driver's
      Clause” of the policy.`,
      `Any consequential loss resulting from any loss or damage to the vehicle.`,
      `Any loss or damage caused by nuclear events.`,
      `Any loss or damage due war and warlike operations.`
    ]
  },
];

// export default BikeInsuranceData;
