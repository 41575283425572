import React from "react";
import InsuranceHeader from "../../../components/InsuranceHeader";
import List from "../../../components/List/List";
import Paragraph from "../../../components/Paragraph";
import Title from "../../../components/Title";
import TriImageCompination from "../../../components/TriImageGalary";
import styles from "./index.module.css";
import { FireInsuranceData } from "../../../Data/FireInsuranceData";

const FireInsurance = () => {
    return (
        <div className="flex flex-column align-items-center justify-content-center gap-2">
          <InsuranceHeader />
          
    
          <div className="w-11 flex flex-column  gap-2 mt-0 lg:mt-8">
            {FireInsuranceData.map((item, index) => {
              switch (item.componentType) {
                case "title":
                  return (
                    <div key={index}>
                      <Title content={item.content} spanText={item.spantext} />{" "}
                    </div>
                  );
                  break;
                case "paragraph":
                  return (
                    <div key={index}>
                      <Paragraph content={item.content} />
                    </div>
                  );
                  break;
                case "list":
                  return (
                    <div key={index}>
                      <List items={item.content} />
                    </div>
                  );
                  break;
              }
            })}
          </div>
        </div>
      );
}

export default FireInsurance