import { Link, useNavigate } from "react-router-dom";
import styles from "./serviceCard.module.css"
const ServiceCard = ({ data, i }) => {
  const navigate = useNavigate();

  return (
    <div
      className={`text-center service-card p-2 flex-1 ${i == 1 && "active"}`}
    >
     <Link to={data.navigateTo} target="_blank"
      className={`flex-1 list-none no-underline`}
      >
      <div className="p-2">
        <img src={data.url} alt={data.title} width={40} height={40} />
      </div>
      <h3 className={` uppercase ${styles.heading}`}>{data.title}</h3>
      <p className={`  ${styles.subHeading}`}>{data.subTitle}</p>
      </Link>
    </div>
  );
};

export default ServiceCard;
