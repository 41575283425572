export const ContractorInsuranceData = [
  {
    componentType: "title",
    content: " Contractor's All",
    spantext: " risk insurance"
  },
  {
    componentType: "paragraph",
    content: `Contractor All Risk policy is designed to cover the risks in executing civil construction projects by
        contractors. It primarily covers the project cost. However, a contractor incurs
        many other
        expenditures which may not be included in the project costs. Further contractor is exposed to many liabilities that may arise during the execution of the project.
        This policy covers such risks also.`
  },
  {
    componentType: "paragraph",
    content: `A contractor while executing a project may be entering into many other contracts for supply of
        material, labour &amp; equipment. The contractor should understand the terms
        of such contracts to
        know whether he is exposed to any risks under such contracts and explore ways to protect from such risks. For e.g. if a contractor is hiring an equipment,
        he should check his responsibilities towards
        safety of the equipment and whether there is any scope to cover the equipment under an insurance
        policy.`
  },
  {
    componentType: "title",
    content: " Coverage:"
  },
  {
    componentType: "paragraph",
    content: `The term “All Risks” policy , also called as “Exclusions Driven” policy, means policy covers all the
        perils which are not excluded. Hence in such policies perils are not listed,
        only exclusions are listed.`
  },
  {
    componentType: "title",
    content: " Exclusions:"
  },
  {
    componentType: "paragraph",
    content: `Exclusions that is applicable to entire policy are`
  },
  {
    componentType: "list",
    content: [
      `War perils`,
      `Nuclear perils`,
      `Willful negligence or willful act of Insured or his authorized representatives`,
      `Cessation of work, whether total or partial`
    ]
  },
  {
    componentType: "paragraph",
    content: `This policy has following sections`
  },
  {
    componentType: "list",
    content: [`Material Damage`, `Third Party Liability`]
  },
  {
    componentType: "title",
    content: " Material ",
    spantext: " damage section"
  },
  {
    componentType: "paragraph",
    content: `This section provides the basis of sum insured, add on covers like extra cost for overtime, express
        freight , additional customs duty, cover for construction plant and machinery,
        surrounding property
        damage, major perils/ AOG perils including cover for “wet” risks.`
  },
  {
    componentType: "list",
    content: [
      `loss discovered only at the time of taking inventory`,
      `loss due to normal wear and tear, gradual deterioration, breakage of glass`,
      `loss or damage due to faulty design`,
      `cost of replacement, repair or rectification of defective material / workmanship`,
      `the cost necessary for rectification or correction of any error during construction unless
          resulting in physical loss or damage;`,
      `loss of or damage to files, drawings, accounts, bills, currency, stamps, deeds, evidence of
          debt, notes, securities, cheques, packing materials suchas cases, boxes, crates`,
      `any damage or penalties on account of the Insured&#39;s non-fulfillment of the terms of delivery
          under contract`
    ]
  },
  {
    componentType: "title",
    content: " Third  ",
    spantext: " part liability"
  },
  {
    componentType: "paragraph",
    content: `This cover will indemnify insured against legal liability for accidental loss or damage caused to
        property of third parties, injury (fatal/non-fatal)to third parties. This section
        does not insured
        liability towards employees.`
  },
  {
    componentType: "paragraph",
    content: `Project contracts may contain many terms and conditions, expressed through a heavily worded
        document. Insured should understand the implications of these terms and
        conditions. Many terms in
        the contract can make insured responsible for certain consequences, so it is advisablefor insured to
        deal with such consequences through
        appropriate insurance.`
  },
  {
    componentType: "paragraph",
    content: `The above details provide a summarized view of the Contractor’s All Risk insurance. Insured should
        analysis entire terms and conditions of the
        policy to decide whether the terms and conditions covers
        the risk he is exposed.`
  }
];
