import GetInTouch from "../../components/GetInTouch";
import Footer from "../../views/Footer";
import Header from "../../views/Header";
import BuyInSteps from "./components/BuyInSteps";
import KeyFeatures from "./components/KeyFeatures";
import MobileBanner from "./components/MobileBanner";

function MobileInsurance() {
  return (
    <div>
      <Header />
      <MobileBanner />
      <KeyFeatures />
      <BuyInSteps />
      <GetInTouch />
      <Footer />
    </div>
  );
}

export default MobileInsurance;
