import { Image } from "primereact/image";
import "./stylesheets/index.css";
import PospLoginForm from "./components/PospLoginForm";

function PospLogin() {
  return (
    <div>
      <div className=" w-11 m-auto flex justify-content-between align-items-center p-4 posp-sighup-container">
        <div className="text-center w-6 p-6">
          <div className=" mb-4">
            <h2 className="m-0">
              Welcome to <span>insuright</span>
            </h2>
            <p className="">Secure Access using your register phone number</p>
          </div>
          <div>
            <Image src="/images/Agreement-amico 1.png" width="400" />
          </div>
        </div>
        <div className="w-6 p-6">
          <PospLoginForm />
        </div>
      </div>
    </div>
  );
}

export default PospLogin;
