import "./App.css";
import MobileInsurance from "./Pages/MobileInsurance";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Home from "./Pages/Home";
import PageNotFound from "./components/PageNotFound";
import PrivacyPolicies from "./views/PrivacyPolicies/PrivacyPolicies";
import TermsAndConditions from "./views/Terms&Conditions/Terms&Conditions";
import BecomePosp from "./Pages/BecomePosp";
import PospLogin from "./Pages/PospLogin";
import ContactUs from "./Pages/ContactUs";
import HealthInsurance from "./Pages/HealthInsurance";
import ClaimAssistance from "./Pages/ClaimAssistance";
import BikeInsurance from "./views/Footer Pages/BikeInsurance/BikeInsurance";
import CarInsurance from "./views/Footer Pages/carInsurance";
import TopupInsurance from "./views/Footer Pages/TopupInsurance";
import TravelInsurance from "./views/Footer Pages/TravelInsurance";
import PersonalAccidentInsurance from "./views/Footer Pages/PersonalAccidentInsurance";
import GroupMediclaim from "./views/Footer Pages/GroupMediclaim";
import GroupPersonalAccidentInsurance from "./views/Footer Pages/GroupPersonalAccidentInsurance";
import FireInsurance from "./views/Footer Pages/FireInsurance";
import MarineInsurance from "./views/Footer Pages/MarineInsurance";
import ContractorInsurance from "./views/Footer Pages/ContractorInsurance";
import ErectionAllInsurance from "./views/Footer Pages/ErectionAllInsurance";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />}>
          <Route path="/privacyPolicies" element={<PrivacyPolicies />} />
          <Route path="terms&conditions" element={<TermsAndConditions />} />
          <Route path="become-a-posp" element={<BecomePosp />} />
          <Route path="posp-login" element={<PospLogin />} />
          <Route path="claim-assistance" element={<ClaimAssistance />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/health-insurance" element={<HealthInsurance />} />
          <Route path="/bike-insurance" element={<BikeInsurance />} />
          <Route path="/car-insurance" element={<CarInsurance />} />
          <Route path="/topup-insurance" element={<TopupInsurance />} />
          <Route path="/travel-insurance" element={<TravelInsurance/>} />
          <Route path="/personal-accident-insurance" element={<PersonalAccidentInsurance />} />
          <Route path="/group-Mediclaim" element={<GroupMediclaim />} />
          <Route path="/group-personal-accidents-insurance" element={<GroupPersonalAccidentInsurance />} />
          <Route path="/fire-insurance" element={<FireInsurance />} />
          <Route path="/marine-insurance" element={<MarineInsurance />} />
          <Route path="/contractor-insurance" element={<ContractorInsurance />} />
          <Route path="/eraction-all-insurance" element={<ErectionAllInsurance />} />
        
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;
