import { Image } from "primereact/image";
import "./index.css";

function TriImageCompination({ images }) {
  return (
    <div className="w-11 m-auto relative flex flex-column lg:flex-row gap-2 mb-6">
      <div className="flex-1 img-container">
        <Image
          src={`https://images.pexels.com/photos/8471859/pexels-photo-8471859.jpeg?auto=compress&cs=tinysrgb&w=800`}
          width="100%"
          height="350"
        />
      </div>
      <div className="flex-1 img-container">
        <Image
          src={`https://images.pexels.com/photos/11655091/pexels-photo-11655091.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2`}
          width="100%"
          height="350"
        />
      </div>
      <div className={"w-12 lg:w-4 lg:absolute relative overflow-image img-container"}>
        <Image
          src={`https://images.pexels.com/photos/11331918/pexels-photo-11331918.jpeg?auto=compress&cs=tinysrgb&w=800`}
          width="100%"
          height="350"
        />
      </div>
    </div>
  );
}

export default TriImageCompination;
