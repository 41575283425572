const PartnerCard = ({ item = null }) => {
  return (
    <div key={item?.id} className="ins_partner px-3 w-full">
      <img
        src={item?.url}
        alt="insurance_parnter"
        style={{ objectFit: "contain", maxWidth: "100%" }}
      />
    </div>
  );
}

export default PartnerCard