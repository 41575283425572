function OverlayDescriptionCard({ data }) {
  return (
    <div className="flex-1 customer-benifit-card">
      <img
        src={data.url}
        style={{
          objectFit: "fill",
          width: "100%",
          height: "100%",
        }}
      />
      <div className="w-full card-info-cont  absolute z-3 p-2 flex flex-column justify-content-start">
        <p className="text-center mt-0">{data.title}</p>
        <p className="card-desc m-0">{data.description}</p>
      </div>
    </div>
  );
}

export default OverlayDescriptionCard;
