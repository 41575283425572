export const FireInsuranceData = [
  {
    componentType: "title",
    content: " Fire ",
    spantext: "insurance"
  },
  {
    componentType: "paragraph",
    content: `Like marine insurance &amp; life insurance, fire insurance is one of oldest forms of insurance. Fire
insurance is the core of property insurance. It has evolved through many legal
judgements. Over a
period of time, practice of fire insurance has evolved to suit changing requirements of customers.
Customers purchase immovable properties like houses,
plant and machinery investing a lot of
money. In relation to these properties, fire insurance offers financial protection in the event such
properties getting destroyed due to
certain events. Knowledge of fire insurance is as important
helpful as knowledge of life insurance, health insurance, motor vehicle insurance. This knowledge
would be of great
help in case the insured property might unfortunately get damaged due to fire or
flood.`
  },
  {
    componentType: "paragraph",
    content: `Like marine insurance &amp; life insurance, fire insurance is one of oldest forms of insurance. Fire
        insurance is the core of property insurance. It has evolved through many legal
        judgements. Over a
        period of time, practice of fire insurance has evolved to suit changing requirements of customers.
        Customers purchase immovable properties like houses,
        plant and machinery investing a lot of
        money. In relation to these properties, fire insurance offers financial protection in the event such
        properties getting destroyed due to
        certain events. Knowledge of fire insurance is as important
        helpful as knowledge of life insurance, health insurance, motor vehicle insurance. This knowledge
        would be of great
        help in case the insured property might unfortunately get damaged due to fire or
        flood.`
  },
  {
    componentType: "paragraph",
    content: `The perils covered under SFSP policy are`
  },
  {
    componentType: "list",
    content: [
      `Fire`,
      `Lightning`,
      `Explosion / Implosion`,
      `Aircraft Damage`,
      `Riot, Strike and Malicious Damage (RSMD)`,
      `Storm, Cyclone, Typhoon, Tempest, Hurricane, Tornado, Flood and Inundation (STFI)`,
      `Impact Damage`,
      `Subsidence and Landslide including Rockslide`,
      `Bursting and/or overflowing of Water Tanks, Apparatus and Pipes`,
      `Missile Testing Operations`,
      `Leakage from Automatic Sprinkler Installations`,
      `Bush Fire: It means fire spread from the bushes.`,
    ]
  },
  {
    componentType: "paragraph",
    content: `The properties that are insured under fire insurance can be broadly grouped as buildings, stocks,
    plant &amp; machinery; furniture, fittings and fittings. Proper accounting
    of these properties under
    appropriate ledger accounts would greatly assist the insurer in assessing the claim.`
  }, {
    componentType: "paragraph",
    content: `Insured to get maximum benefit of fire insurance should give extra attention to the sum insured.
    Sum insured under fire policy should reflect the reinstatement value of the
    property at the time of
    loss. Instalment value is the otherwise be termed as present-day new replacement value(PDNRV), where in depreciation is not applied in the calculation.
    Hence insured should avoid relying
    on their accounting records as the value of assets recorded therein would depreciate value. When
    insuring imported equipment care should
    be taken to consider the exchange rate inflation. The
    current or predicted exchange rate should be considered in arriving PDNRV, not the historical
    exchange rate.`
  }, {
    componentType: "paragraph",
    content: `If the sum insured is less than PDNRV at the time of loss, the claim will be proportionately reduced.
    This is called under insurance or condition of average. A casual or indifferent
    approach in fixing sum
    insured can result in huge under insurance and insured gets heavily penalised because of proportional reduction in claim amount payable.`
  }, 
  {
    componentType: "title",
    content: " Types of policies:",
  },
  {
    componentType: "paragraph",
    content: `To deal with fluctuating nature of stocks over a period of time and over multiple locations special
    types of policies like floater policies, declaration policies, floater declaration
    policies can be opted
    for.`
  },
  {
    componentType: "title",
    content: " Add on covers :",
  },
  {
    componentType: "paragraph",
    content: `The add on covers include`
  },
  {
    componentType: "list",
    content: [
      `Combustion (by Fire Only)`,
      `Earthquake (Fire and Shock)`,
      `Forest Fire`,
      `Impact damage due to Insured's Own Vehicle and the articles dropped from them.`,
      `Deterioration of Stocks in cold storage premises due to accidental power failure consequent
      to damage at the premises of power station due to an
      insured peril. (applicable only when
      stock is covered)`,
      `Deterioration of Stocks in cold storage premises due change in temperature arising out
      of loss or damage to the cold storage machinery in the
      Insured's premises due to
      operation of an insured Peril. (Applicable only when stock is covered)`,
      `Architects etc. fees (more than 3%)`,
      `Debris Removal (more than 1%)`,
      `Omission to Insure, Additions, Alterations, Extensions Clause`,
      `Spoilage Material Damage Cover (applicable to stock and machinery containers only)`,
      `Leakage and Contamination Cover`,
      `Temporary Removal of Stocks Clause`,
      `Loss of Rent Clause`,
      `Insurance of Additional Expenses of Rent for An Alternate Accommodation`,
      `Start Up Expenses.`,
    ]
  },
  {
    componentType: "paragraph",
    content: `IN 2021 insurance companies introduced “Bharat” series of fire insurance policies. A comparative
    chart given will`
  },
];
