import ResponsiveCarousel from "../../components/Carousels/ResponsiveCarousel";
import ins_partners from "../../utils/ins_partners.json";
import PartnerCard from "../../components/Cards/partnerCard";

export default function Partners() {
  return (
    <div className="partner-container">
      <div className="w-11 m-auto py-4">
        <h1>
          We work with Leading{" "}
          <span style={{ color: "#03A67C" }}>Insurance Partners</span>
        </h1>
        <div className=" my-6 flex flex-wrap gap-6 justify-content-center aling-items-center">
          <ResponsiveCarousel items={ins_partners} itemTemplate={PartnerCard} />
        </div>
      </div>
    </div>
  );
}
