import { useState } from "react";
import { InputText } from "primereact/inputtext";
import CustomButton from "../../../components/CustomButton";
import { Link } from "react-router-dom";
function PospLoginForm() {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");

  return (
    <div className="text-center ">
      <h2>POSP login</h2>

      <div className="mb-3">
        <InputText
          placeholder="Mobile number"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          className="w-full"
        />
      </div>
      <div>
        <InputText
          placeholder="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full"
        />
      </div>
      <div className="text-right text-sm mt-1 mb-3">
        <Link to="/become-a-posp" className="register-link" color="#000000">
          Forgot password?
        </Link>
      </div>
      <div>
        <CustomButton label="Login" className="w-full" />
        <p className="text-center text-sm">OR</p>
        <CustomButton label="Login with OTP" className="w-full" />
        <p className="text-center text-sm">
          Don't have account?{" "}
          <Link to="/become-a-posp" className="register-link" color="#000000">
            Register Now
          </Link>
        </p>
      </div>
    </div>
  );
}

export default PospLoginForm;
