import "../stylesheets/footer-link-title.css";
function FooterLinkTitle() {
  return (
    <div className="footer-link-title p-3">
      <p className="m-0">USEFUL LINKS-ABOUT INSURENCE</p>
    </div>
  );
}

export default FooterLinkTitle;
