import TriImageCompination from "../../components/TriImageGalary";

function HealthInsurance() {
  return (
    <div className="w-11 m-auto">
      <h2 className="text-center">
        Health <span className="green-text">Insurance Services</span>
      </h2>
      <div className="mb-6">
        <TriImageCompination />
      </div>

      <div className="pt-6 ">
        <h2>
          Medical <span className="green-text">Insurance</span>
        </h2>
        <p>
          Considering the rise in healthcare costs, higher incidences of
          lifestyle diseases, the availability of various healthcare procedures,
          evolution of healthcare service to an industry level status etc. has
          made health insurance as the most impactful insurance schemes. Further
          healthcare insurance coverages have also evolved keeping pace with the
          developments in healthcare sector through extensive customization.
          Developments over last two decades in health insurance includes
          introduction of Third-Party Administrators, group insurance schemes,
          networking of hospitals for cashless service, licensing of exclusive
          health insurance companies, introduction of various health insurance
          schemes both by Central and State Governments. Such developments have
          brought very high visibility to health insurance and has made it a
          priority insurance protection cover that is being sought.
        </p>
        <p>
          Primarily, health insurance policies are designed to cover
          hospitalization expenses incurred for the treatment of an ailment in a
          hospital. All other covers, which could be add on covers or
          extensions, are related to this hospitalisation.
        </p>
        <h2>
          Hospitalisation <span className="green-text">expenses:</span>
        </h2>
        <p>
          Cover for hospitalisation expenses includes bed charges, nursing
          charges, ICU charges, OT charges, consultation fees, attending
          physician charges, surgeon fees, anesthesia, oxygen, blood bank,
          implants, diagnostics, pharmacy, consumables etc.
        </p>
        <h2>
          Pre & post-hospitlisation{" "}
          <span className="green-text">expenses:</span>
        </h2>
        <p>
          Expenses incurred prior to hospitalisation and subsequent to
          hospitalisation are referred as pre- hospitalisation and
          post-hospitalisation respectively. Pre-hospitalisation expenses is
          largely incurred for diagnosis and consultation prior to
          hospitalisation. Post-hospitalisation expenses is incurred for follow-
          up treatments, consultation after discharge from hospital. These
          expenses are covered in the policy for ascertain specified period. It
          could be 15 days / 30 days / 45 days / 60 days for pre-hospitalization
          &amp; 30 days / 45 days / 60 days / 75days for post-hospitalisation.
          Many a times the period covered for pre &amp; post-hospitalisation is
          expressed in the format “15 / 30” . “15”refers to coverage for
          expenses incurred 15 days of prior to hospitalisation &amp; “30”
          refers to coverage for expenses incurred for 30days after
          hospitalisation.
        </p>
        <h2>
          Daycare <span className="green-text">procedures:</span>
        </h2>
        <p>
          With the advent of new medical treatment procedures, many treatments
          do not require hospitalisation for more than 24 hrs. Patient
          undergoing such treatments are treated and discharged on the same day.
          These treatment procedures are called as day care procedures.
          Insurance polices provides a list of such day care procedures covered.
        </p>
        <h2>
          Pre-existing <span className="green-text">diseases:</span>
        </h2>
        <p>
          It is very important to understand how policy defines pre-existing
          diseases because insurance policies do not cover pre-existing
          diseases. However, policy covers such pre-existing diseases after a
          certain waiting period from the date of commencement of first
          insurance cover. This waiting period could be up to 48 months. If the
          insurance policy is not renewed, then waiting period will be reset and
          will be considered from date of commencement of subsequent policy.
          Hence to remove the limitation imposed by the waiting period it is
          very essential that the policy is renewed without any
        </p>
        <h2>
          Maternity <span className="green-text">expenses::</span>
        </h2>
        <p>
          Customisation of health insurance over a period of time has resulted
          in covering maternity expenses in certain health insurance policies.
          Customers who are looking for maternity cover in their health
          insurance programme should specifically examine whether maternity
          coveris included or excluded and if included terms and conditions of
          maternity cover.
        </p>
        <h2>
          Restoration of <span className="green-text">sum insured:</span>
        </h2>
        <p>
          In health insurance the sum insured (amount for which insurance policy
          has been taken) gets reduced to the extent of claim paid .For example
          if the sum insured under the policy is Rs 5 lakhs, and the claim paid
          in the 6 th month of the policy is Rs 2 lakhs, then for the balance
          period of the policy the sum insured available for the insured under
          this policy is Rs 4 lakhs. If the policy has a provision of
          restoration of sum insured then sum insured under the policy gets
          restored to original sum insured of Rs 5 lakhs. Customer looking for
          this feature should specifically check policy terms and understand the
          terms and conditions on restoration of sum insured following a claim.
        </p>
      </div>
    </div>
  );
}

export default HealthInsurance;
