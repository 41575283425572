import React from "react";
import "./style.css";

const CustomeParagraph = ({ context, className = "", style = {} }) => {
  return (
    <h3 className={`basic ${className} `} style={style}>
      {context}
    </h3>
  );
};

export default CustomeParagraph;
