import React from 'react'
import styles from "./style.module.css"

const Title = ({content="", spanText=""}) => {
  return (
    <div>
        <p className={styles.content} >
        {content}
        <span className='green-text'>{spanText}</span>
        </p>
        
    </div>
  )
}

export default Title