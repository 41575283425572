export const MarineInsuranceData = [
      {
        componentType: "title",
        content: " Personal",
        spantext: " accident insurance"
      },
      {
        componentType: "paragraph",
        content: `Like fire insurance, which deals with insurance of property, marine (cargo) insurance also deals with
        insurance of property. The difference is that fire insurance covers properties
        located in a premises
        whereas marine (cargo) insurance covers properties in transit. Hence marine (cargo) insurance isalso called as transit insurance.`
      },
      {
        componentType: "paragraph",
        content: `There is a perception that marine (cargo) insurance deals only with insurance of cargo on ship in
        ocean transit. Marine (cargo) insurance deals with goods transported on road,
        rail, air, courier both
        inland and overseas.`
      },
      {
        componentType: "title",
        content: " Coverage:",
      },
      {
        componentType: "paragraph",
        content: `Marine (cargo) insurance coves loss or damage to the cargo consignment during its transit from
        consigner's place to its destination at consignee splace, due to various causes.
        These causes could
        be fire, lightning, theft, natural disasters like storm, accident to carrying vehicle k like truck train or ship, piracy, losses whilst cargo is being handled.`
      },
      {
        componentType: "paragraph",
        content: `Policies can be suitably designed to cover exports, imports and inland transit.`
      },
      {
        componentType: "paragraph",
        content: `Marine cargo insurance is required for any business in the supply chain. This includes`
      },
      {
        componentType: "list",
        content: [
          `Manufacturers`,
          `Buyers`,
          `Sellers`,
          `Importers / Exporters in trading activity`,
          `Bankers who issue Letter of credit`,
        ]
      },
      {
        componentType: "title",
        content: " Exclusions:",
      },
      {
        componentType: "paragraph",
        content: `Policy exclusions include losses due to will ful negligence, improper packaging, inherent vice, loss due
        to delay even though the cause of delay could be an insured peril.`
      },
      {
        componentType: "title",
        content: " Rights of recovery:",
      },
      {
        componentType: "paragraph",
        content: `A consignment on leaving the custody of the consigner, is handled by many agents like local
        transporter, customs, ports, shipping company. Loss could happen while the
        consignment is in the
        custody of any of these agents. Hence it is essential for the claimant to know the who is responsible
        for the loss and lodge a monetary claim for the loss.
        Hence the claimant should be familiar with such
        rules and regulations so that their rights of recovery is protected.`
      },
]