import { Image } from "primereact/image";
import "./stylesheets/index.css";
import PospSignupForm from "./components/PospSignupForm";
function BecomePosp() {
  return (
    <div>
      <div className=" w-11 m-auto flex justify-content-between align-items-center p-4 posp-sighup-container">
        <div className="w-6 p-6">
          <Image src="/images/Agreement-amico 1.png" width="400" />
        </div>
        <div className="w-6 p-6">
          <PospSignupForm />
        </div>
      </div>
    </div>
  );
}

export default BecomePosp;
