import { useState } from "react";
import { InputText } from "primereact/inputtext";
import CustomButton from "../../../components/CustomButton";
function PospSignupForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");

  return (
    <div className="text-center ">
      <h2>POSP signup</h2>
      <div className="mb-3">
        <InputText
          placeholder="Enter your name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full"
        />
      </div>
      <div className="mb-3">
        <InputText
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full"
        />
      </div>
      <div className="mb-3">
        <InputText
          placeholder="Mobile number"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          className="w-full"
        />
      </div>
      <CustomButton label="Get Signup" className="w-full" />
    </div>
  );
}

export default PospSignupForm;
