import { useState } from "react";
import { InputText } from "primereact/inputtext";
import CustomButton from "../../../components/CustomButton";
import { Link } from "react-router-dom";
import { InputTextarea } from "primereact/inputtextarea";
function ContactForm() {
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");

  return (
    <div className="text-center ">
      <div className="mb-3">
        <InputText
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full"
        />
      </div>
      <div className="mb-3">
        <InputText
          placeholder="Enter your email"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full"
        />
      </div>
      <div className="mb-3">
        <InputText
          placeholder="Mobile number"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          className="w-full"
        />
      </div>
      <div className="mb-3">
        <InputText
          placeholder="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          className="w-full"
        />
      </div>
      <div className="mb-3">
        <InputTextarea
          placeholder="Subject"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="w-full"
        />
      </div>

      <div>
        <CustomButton label="Get started Today" className="w-full" />
      </div>
    </div>
  );
}

export default ContactForm;
