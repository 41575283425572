import ServiceCard from "./components/ServiceCard";
import services from "../../utils/searvies.json";
function ServicesNavigation() {
  return (
    <div className="flex jutify-content-center align-items-center gap-1">
      {services?.map((item, i) => (
        <ServiceCard data={item} key={item.id} i={i} />
      ))}
    </div>
  );
}

export default ServicesNavigation;
