function InsurightInfo() {
  return (
    <>
      <h1 className=" my-3 text-4xl lg:text-6xl">
        Why insuright Insurance Should Be Your{" "}
        <span style={{ color: "#03A67C" }}>Top Choice</span>
      </h1>
      <p className="w-full lg:w-9">
        Insuright Insurance offers customizable insurance plans tailored to your
        specific needs. Whether you're looking for auto, home, life, health, or
        business insurance, they provide options that fit your requirements and
        budget.
      </p>
    </>
  );
}

export default InsurightInfo;
