import React from 'react'
import styles from "./style.module.css"

const Paragraph = ({content}) => {
  return (
    <div>
        <p className={styles.content}> 
            {content}
        </p>
    </div>
  )
}

export default Paragraph