import { Carousel } from "primereact/carousel";
import "./style.css";
export default function CustomerReviewCarousel({ items = [] }) {
  const responsiveOptions = [
    {
      breakpoint: "1400px",
      numVisible: 4,
      numScroll: 1,
    },
    {
      breakpoint: "1199px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  const customerReviewCard = (customer) => {
    return (
      <div
        key={customer.id}
        className="p-4 customer-review-card w-11 mx-auto my-3 "
      >
        <div className="flex gap-2 align-items-center">
          <div className="profile-pic">
            <img src={customer.url} width={66} height={66} />
          </div>
          <div>
            <h4 className="m-0">{customer.name}</h4>
            <small className="m-0">{customer.customer_type}</small>
          </div>
        </div>
        <p className="customer-message">{customer.message}</p>
        <div>
          <i className="pi pi-star-fill pr-3" style={{ color: "#03A67C" }} />
          <i className="pi pi-star-fill pr-3" style={{ color: "#03A67C" }} />
          <i className="pi pi-star-fill pr-3" style={{ color: "#03A67C" }} />
          <i className="pi pi-star-fill pr-3" style={{ color: "#03A67C" }} />
          <i className="pi pi-star" />
        </div>
      </div>
    );
  };
  return (
    <div className="w-full">
      <Carousel
        value={items}
        numScroll={1}
        numVisible={4}
        responsiveOptions={responsiveOptions}
        itemTemplate={customerReviewCard}
        showIndicators={false}
        autoplayInterval={3000}
        circular
      />
    </div>
  );
}
