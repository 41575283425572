import React from 'react'
import classes from "./terms&Conditions.module.css"
import termsAndConditions from "../../utils/terms&condition.json"
import CustomeParagraph from '../../components/CustomParagraph/CustomeParagraph'

const TermsAndConditions = () => {
  return (
    <div className={classes.container} >
        <h3 className={classes.TermsAndCondition} >Terms & Conditions</h3>
        <h3 className={classes.heading} >Introduction</h3>
        <h3 className={classes.heading}>Thank you for visiting our website <a className={classes.link} target='_blank' href='https://www.apnainsurance.in/' > www.Insuright.co.in </a>  </h3>
        {termsAndConditions.map((item,index)=>{
            switch (item.componentType){

                case "title":
                    return (
                        <div key={index}> 
                        <CustomeParagraph  context={item.context} className={item.className} style={item.style} />
                        </div>
                    )
                    break;
                case "paragraph":
                    return (
                        <div key={index}>
                        <CustomeParagraph  context={item.context} className={item.className} />
                        </div>
                    )
                    break;
            }
        })}
    </div>
  )
}

export default TermsAndConditions