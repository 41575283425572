import { Button } from "primereact/button";
import "./index.css";
function CustomButton({
  label = "",
  varient = "filled",
  className = "",
  onClick = () => {},
}) {
  return (
    <Button
      className={`${varient} ${className} text-center`}
      onClick={onClick}
      label={label}
    />
  );
}

export default CustomButton;
