const headerNavList = [
  {
    label: "Become a Posp",
    route: "/become-a-posp",
    icon: "pi pi-external-link",
  },
  {
    label: "POSP login",
    route: "/posp-login",
    icon: "pi pi-external-link",
  },
  // {
  //   label: "Customer login",
  //   route: "/customer-login",
  //   icon: "pi pi-external-link",
  // },
  {
    label: "Claim Assistance",
    route: "/claim-assistance",
    icon: "pi pi-external-link",
  },
  {
    label: "Contact us",
    route: "/contact-us",
    icon: "",
  },
];

export default headerNavList;
