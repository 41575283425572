import React from 'react'
import CustomForm from '../CustomForm';
import "./index.css";


const InsuranceHeader = () => {
    
      const handleFormSubmit = (data) => {
        console.log('Form data:', data);
      };
  return (
    <div className="gap-2 lg:gap-0 flex flex-column lg:flex-row justify-content-around align-items-center  background-container  py-6 ">
        <div className="flex flex-column  z-1">
          <p className="l1">Buy Your</p>
          <p className="l2">INSURANCE</p>
          <p className="l3">
            in a Smart Way, with <span className="span">One click </span>
          </p>
        </div>
        <div className="z-1">
          {/* <CustomForm onSubmit={handleFormSubmit} /> */}
          <CustomForm  />
        </div>
      </div>
  )
}

export default InsuranceHeader