import React from 'react'
import classes from "./pageNotFound.module.css";
import { useNavigate, useLocation } from "react-router-dom";

const PageNotFound = () => {
    const navigate = useNavigate();
    return (
        <div className={classes.container}>
          <h1  className={classes.error}>404 - Page Not Found</h1>
          <div
            className="flex justify-content-center align-items-center gap-2 "
            onClick={() => navigate("/")}
          >
            <div className="flex justify-content-center align-items-center cursor-pointer ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-left "
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
                />
              </svg>
            </div>
            <span style={{ fontWeight: 600, cursor: "pointer" }}>Back To Home</span>
          </div>
        </div>
      );
}

export default PageNotFound