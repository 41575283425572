import React from "react";

import customerService from "../../utils/asCutomer.json";
import cusomter_reviews from "../../utils/customer_reviews.json";

import CustomerReviewCarousel from "../../components/Carousels/CustomerReviewCarousel";
import BannerCarousel from "../../components/BannerCarousel";
import OverlayDescriptionCard from "../../components/Cards/OverlayDescriptionCard";
import InsurightInfo from "../../components/InsurightInfo";
import DownloadInsurightApp from "../../views/DownloadInsurightApp";

import Partners from "../../views/Partners";
import ServicesNavigation from "../../views/ServicesNavigation";

const HomePage = () => {
  return (
    <>
      <main className="relative mb-8">
        <BannerCarousel />
        <div className="absolute servies-nav w-11 lg:w-10 p-2">
          <ServicesNavigation />
        </div>
      </main>
      <div className=" w-11 m-auto pt-4">
        <InsurightInfo />
        <div className="py-3">
          <h4>As a Customer</h4>
          <div>
            <div className="customer-benifits flex gap-3 overflow-x-auto">
              {customerService?.map((item) => (
                <OverlayDescriptionCard data={item} key={item.id} />
              ))}
            </div>
          </div>
        </div>
        <div>
          <h4>As a POSP PARTNER</h4>
          <div>
            <div className="customer-benifits flex gap-3 overflow-x-auto">
              {customerService?.map((item) => (
                <OverlayDescriptionCard data={item} key={item.id} />
              ))}
            </div>
          </div>
        </div>

        <div>
          <h1>
            What Our Customers{" "}
            <span style={{ color: "#03A67C" }}>Are Saying?</span>
          </h1>
          <div className="my-6 flex gap-5 flex-wrap justify-content-center">
            <CustomerReviewCarousel items={cusomter_reviews} />
          </div>
        </div>
      </div>
      {/* <div className="w-11 m-auto">
        <DownloadInsurightApp />
      </div> */}
      <Partners />
    </>
  );
};

export default HomePage;
