import { Image } from "primereact/image";
import "./stylesheets/index.css";
import ContactForm from "./components/ContactForm";

function ContactUs() {
  return (
    <div>
      <div className=" w-11 m-auto flex justify-content-between align-items-start p-4 posp-sighup-container">
        <div className="text-center w-6 p-6">
          <div className="w-9 m-auto">
            <div className="mb-4">
              <h2 className="m-0">Contact Us</h2>
            </div>
            <div className="text-left">
              <div className="flex gap-3 ">
                <Image src={"/icons/home.svg"} width="24" height="24" />

                <p className="mt-0">
                  Insuright Insurance Broking Private Ltd 3rd Floor, Above ICICI
                  Bank, The Senate IBC, Ulsoor Rd, Halasuru, Hanumanthappa
                  Layout, Sivanchetti Gardens, Bengaluru PIN - 560008
                </p>
              </div>
              <div className="flex gap-3 ">
                <Image src={"/icons/phone.svg"} width="24" height="24" />
                <p className="mt-0">+91 8147829997</p>
              </div>
              <div className="flex gap-3 ">
                <Image src={"/icons/clock.svg"} width="24" height="24" />
                <p className="mt-0">Monday to Friday - 9.30 AM to 6.30 PM</p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-6 p-6">
          <ContactForm />
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
